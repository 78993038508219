(function (Translator) {
    // pt
    Translator.add("modal.flash_message.title", "Informa\u00e7\u00e3o Importante", "FlashMessages", "pt");
    Translator.add("modal.default.close", "Fechar", "FlashMessages", "pt");
    Translator.add("contact_us.success", "Obrigado pela sua mensagem. N\u00f3s responderemos o mais r\u00e1pido poss\u00edvel", "FlashMessages", "pt");
    Translator.add("company_edit.success", "Informa\u00e7\u00f5es da empresa mudadas", "FlashMessages", "pt");
    Translator.add("shopping.checkout.error", "Algo correu mal. Por favor, tente novamente.", "FlashMessages", "pt");
    Translator.add("shopping.checkout.no_items", "O seu carrinho est\u00e1 vazio!", "FlashMessages", "pt");
    Translator.add("search.error", "Algo correu mal. Tente procurar novamente.", "FlashMessages", "pt");
    Translator.add("product.add.success", "Produto adicionado!", "FlashMessages", "pt");
    Translator.add("product.add.fail", "Erro ao adicionar o produto. Por favor, tente novamente mais tarde...", "FlashMessages", "pt");
    Translator.add("product.edit.success", "Quantidade do produto alterada!", "FlashMessages", "pt");
    Translator.add("product.edit.fail", "Erro ao mudar a quantidade do produto. Por favor, tente novamente mais tarde...", "FlashMessages", "pt");
    Translator.add("product.delete.success", "Producto exclu\u00eddo!", "FlashMessages", "pt");
    Translator.add("product.delete.fail", "Erro ao remover o produto. Por favor, tente novamente mais tarde...", "FlashMessages", "pt");
    Translator.add("profile.updated", "Perfil atualizado", "FlashMessages", "pt");
    Translator.add("password_changed", "A password foi atualizada", "FlashMessages", "pt");
})(Translator);
