(function (Translator) {
    // en
    Translator.add("title_user_registration", "Register", "SonataUserBundle", "en");
    Translator.add("title_user_account", "User Profile", "SonataUserBundle", "en");
    Translator.add("title_user_edit_profile", "Edit", "SonataUserBundle", "en");
    Translator.add("title_user_authentication", "Authentication", "SonataUserBundle", "en");
    Translator.add("sonata_user_submit", "Submit", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_user_list", "Users", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_user_create", "Create", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_user_edit", "Edit", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_user_delete", "Delete", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_group_list", "Groups", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_group_create", "Create", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_group_edit", "Edit", "SonataUserBundle", "en");
    Translator.add("users", "Users", "SonataUserBundle", "en");
    Translator.add("groups", "Groups", "SonataUserBundle", "en");
    Translator.add("switch_user_exit", "Exit impersonating mode", "SonataUserBundle", "en");
    Translator.add("switch_user", "Impersonate User", "SonataUserBundle", "en");
    Translator.add("user_block_logout", "Logout", "SonataUserBundle", "en");
    Translator.add("user_block_profile", "Profile", "SonataUserBundle", "en");
    Translator.add("form.label_username", "Username", "SonataUserBundle", "en");
    Translator.add("form.label_email", "E-Mail-Address", "SonataUserBundle", "en");
    Translator.add("form.label_plain_password", "Plain password", "SonataUserBundle", "en");
    Translator.add("form.label_groups", "Groups", "SonataUserBundle", "en");
    Translator.add("form.label_roles", "Roles", "SonataUserBundle", "en");
    Translator.add("form.label_locked", "Locked", "SonataUserBundle", "en");
    Translator.add("form.label_expired", "Expired", "SonataUserBundle", "en");
    Translator.add("form.label_enabled", "Enabled", "SonataUserBundle", "en");
    Translator.add("form.label_credentials_expired", "Credentials expired", "SonataUserBundle", "en");
    Translator.add("form.label_created_at", "Created at", "SonataUserBundle", "en");
    Translator.add("form.label_last_login", "Last login", "SonataUserBundle", "en");
    Translator.add("form.label_name", "Name", "SonataUserBundle", "en");
    Translator.add("filter.label_username", "Username", "SonataUserBundle", "en");
    Translator.add("filter.label_name", "Name", "SonataUserBundle", "en");
    Translator.add("filter.label_email", "E-Mail-Address", "SonataUserBundle", "en");
    Translator.add("filter.label_locked", "Locked", "SonataUserBundle", "en");
    Translator.add("filter.label_id", "ID", "SonataUserBundle", "en");
    Translator.add("filter.label_enabled", "Active", "SonataUserBundle", "en");
    Translator.add("filter.label_groups", "Groups", "SonataUserBundle", "en");
    Translator.add("filter.label_created_at", "Created at", "SonataUserBundle", "en");
    Translator.add("filter.label_last_login", "Last login", "SonataUserBundle", "en");
    Translator.add("filter.label_firstname", "Firstname", "SonataUserBundle", "en");
    Translator.add("filter.label_lastname", "Lastname", "SonataUserBundle", "en");
    Translator.add("filter.label_roles", "Roles", "SonataUserBundle", "en");
    Translator.add("list.label_username", "Username", "SonataUserBundle", "en");
    Translator.add("list.label_name", "Name", "SonataUserBundle", "en");
    Translator.add("list.label_email", "E-Mail-Address", "SonataUserBundle", "en");
    Translator.add("list.label_groups", "Groups", "SonataUserBundle", "en");
    Translator.add("list.label_locked", "Locked", "SonataUserBundle", "en");
    Translator.add("list.label_enabled", "Enabled", "SonataUserBundle", "en");
    Translator.add("list.label_created_at", "Created at", "SonataUserBundle", "en");
    Translator.add("list.label_last_login", "Last login", "SonataUserBundle", "en");
    Translator.add("list.label_impersonating", "Impersonate User", "SonataUserBundle", "en");
    Translator.add("list.label_roles", "Roles", "SonataUserBundle", "en");
    Translator.add("list.label_firstname", "Firstname", "SonataUserBundle", "en");
    Translator.add("list.label_lastname", "Lastname", "SonataUserBundle", "en");
    Translator.add("label_two_step_code", "Two-step verification", "SonataUserBundle", "en");
    Translator.add("message_two_step_code_help", "Enter the verification code generated by your mobile application.", "SonataUserBundle", "en");
    Translator.add("label_two_step_code_error", "The verification code is not valid", "SonataUserBundle", "en");
    Translator.add("sonata_user", "Users", "SonataUserBundle", "en");
    Translator.add("General", "General", "SonataUserBundle", "en");
    Translator.add("Groups", "Groups", "SonataUserBundle", "en");
    Translator.add("Management", "Management", "SonataUserBundle", "en");
    Translator.add("field.label_roles_editable", "Roles", "SonataUserBundle", "en");
    Translator.add("form.label_date_of_birth", "Date of birth", "SonataUserBundle", "en");
    Translator.add("form.label_firstname", "Firstname", "SonataUserBundle", "en");
    Translator.add("form.label_lastname", "Lastname", "SonataUserBundle", "en");
    Translator.add("form.label_website", "Website", "SonataUserBundle", "en");
    Translator.add("form.label_biography", "Biography", "SonataUserBundle", "en");
    Translator.add("form.label_gender", "Gender", "SonataUserBundle", "en");
    Translator.add("form.label_locale", "Locale", "SonataUserBundle", "en");
    Translator.add("form.label_timezone", "Timezone", "SonataUserBundle", "en");
    Translator.add("form.label_phone", "Phone", "SonataUserBundle", "en");
    Translator.add("form.label_facebook_uid", "Facebook Uid", "SonataUserBundle", "en");
    Translator.add("form.label_facebook_name", "Facebook Name", "SonataUserBundle", "en");
    Translator.add("form.label_twitter_uid", "Twitter Uid", "SonataUserBundle", "en");
    Translator.add("form.label_twitter_name", "Twitter Name", "SonataUserBundle", "en");
    Translator.add("form.label_gplus_uid", "Google+ Uid", "SonataUserBundle", "en");
    Translator.add("form.label_gplus_name", "Google+ Name", "SonataUserBundle", "en");
    Translator.add("form.label_token", "Token", "SonataUserBundle", "en");
    Translator.add("form.label_two_step_verification_code", "Two Step Verification Code", "SonataUserBundle", "en");
    Translator.add("show.label_username", "Username", "SonataUserBundle", "en");
    Translator.add("show.label_email", "Email", "SonataUserBundle", "en");
    Translator.add("show.label_groups", "Groups", "SonataUserBundle", "en");
    Translator.add("show.label_date_of_birth", "Date of birth", "SonataUserBundle", "en");
    Translator.add("show.label_firstname", "Firstname", "SonataUserBundle", "en");
    Translator.add("show.label_lastname", "Lastname", "SonataUserBundle", "en");
    Translator.add("show.label_website", "Website", "SonataUserBundle", "en");
    Translator.add("show.label_biography", "Biography", "SonataUserBundle", "en");
    Translator.add("show.label_gender", "Gender", "SonataUserBundle", "en");
    Translator.add("show.label_locale", "Locale", "SonataUserBundle", "en");
    Translator.add("show.label_timezone", "Timezone", "SonataUserBundle", "en");
    Translator.add("show.label_phone", "Phone", "SonataUserBundle", "en");
    Translator.add("show.label_facebook_uid", "Facebook Uid", "SonataUserBundle", "en");
    Translator.add("show.label_facebook_name", "Facebook Name", "SonataUserBundle", "en");
    Translator.add("show.label_twitter_uid", "Twitter Uid", "SonataUserBundle", "en");
    Translator.add("show.label_twitter_name", "Twitter Name", "SonataUserBundle", "en");
    Translator.add("show.label_gplus_uid", "Google+ Uid", "SonataUserBundle", "en");
    Translator.add("show.label_gplus_name", "Google+ Name", "SonataUserBundle", "en");
    Translator.add("show.label_token", "Token", "SonataUserBundle", "en");
    Translator.add("show.label_two_step_verification_code", "Two Step Verification Code", "SonataUserBundle", "en");
    Translator.add("show.label_created_at", "Created at", "SonataUserBundle", "en");
    Translator.add("show.label_last_login", "Last login", "SonataUserBundle", "en");
    Translator.add("breadcrumb.link_user_show", "Show", "SonataUserBundle", "en");
    Translator.add("gender_unknown", "unknown", "SonataUserBundle", "en");
    Translator.add("gender_female", "female", "SonataUserBundle", "en");
    Translator.add("gender_male", "male", "SonataUserBundle", "en");
    Translator.add("sonata_profile_title", "Dashboard", "SonataUserBundle", "en");
    Translator.add("link_show_profile", "Dashboard", "SonataUserBundle", "en");
    Translator.add("link_edit_profile", "Profile", "SonataUserBundle", "en");
    Translator.add("title_user_edit_authentication", "Authentication", "SonataUserBundle", "en");
    Translator.add("link_edit_authentication", "Authentication", "SonataUserBundle", "en");
    Translator.add("label_profile_gender", "Gender", "SonataUserBundle", "en");
    Translator.add("label_profile_firstname", "Firstname", "SonataUserBundle", "en");
    Translator.add("label_profile_lastname", "Lastname", "SonataUserBundle", "en");
    Translator.add("label_profile_website", "Website", "SonataUserBundle", "en");
    Translator.add("label_profile_dob", "Date of birth", "SonataUserBundle", "en");
    Translator.add("label_profile_biography", "Biography", "SonataUserBundle", "en");
    Translator.add("label_profile_locale", "Locale", "SonataUserBundle", "en");
    Translator.add("label_profile_timezone", "Timezone", "SonataUserBundle", "en");
    Translator.add("label_profile_phone", "Phone", "SonataUserBundle", "en");
    Translator.add("profile.flash.updated", "Your profile has been updated.", "SonataUserBundle", "en");
    Translator.add("sonata_change_password_link", "Change your password", "SonataUserBundle", "en");
    Translator.add("link_register", "Register", "SonataUserBundle", "en");
    Translator.add("link_login", "Log In", "SonataUserBundle", "en");
    Translator.add("link_logout", "Log out", "SonataUserBundle", "en");
    Translator.add("registration.flash.user_created", "Your account has been created successfully!", "SonataUserBundle", "en");
    Translator.add("forgotten_password", "Forgotten password?", "SonataUserBundle", "en");
    Translator.add("sonata_user_profile_breadcrumb_index", "Dashboard", "SonataUserBundle", "en");
    Translator.add("sonata_user_profile_breadcrumb_edit", "Edit profile", "SonataUserBundle", "en");
    Translator.add("sonata_user_already_authenticated", "You are already logged in", "SonataUserBundle", "en");
    Translator.add("security.login.username", "Username", "SonataUserBundle", "en");
    Translator.add("security.login.password", "Password", "SonataUserBundle", "en");
    Translator.add("form.username", "Username", "SonataUserBundle", "en");
    Translator.add("form.email", "Email", "SonataUserBundle", "en");
    Translator.add("form.password", "Password", "SonataUserBundle", "en");
    Translator.add("form.password_confirmation", "Verification", "SonataUserBundle", "en");
    Translator.add("title_user_edit_password", "Change password", "SonataUserBundle", "en");
    Translator.add("title_user_resetting", "Request password", "SonataUserBundle", "en");
    Translator.add("title_user_resetting_reset", "Reset password", "SonataUserBundle", "en");
})(Translator);
