(function (Translator) {
    // en
    Translator.add("welcome.subject", "Your account as been approved. Please confirm your account. - K-Lighting", "emails", "en");
    Translator.add("welcome.body.top", "Hi, %name%!<br \/>Welcome to K-Lighting online shop!<br \/>", "emails", "en");
    Translator.add("welcome.body.center", "<p>Here you can find all of our products with a great deals!<br \/> You can download our catalogs and Price lists and also place your orders with us.<\/p><p>It's all crystal clear! have a look<\/p>", "emails", "en");
    Translator.add("welcome.activate.button", "Active your account here", "emails", "en");
    Translator.add("welcome.body.know_more", "Your account as been approved", "emails", "en");
    Translator.add("welcome.banner.top.alt", "Welcome to K-lighting by Candibambu", "emails", "en");
    Translator.add("order.subject", "Update in order %number% in K-lighting shop", "emails", "en");
    Translator.add("order.header", "Update in order %number%", "emails", "en");
    Translator.add("order.sub_header", "K-Lighting shop", "emails", "en");
    Translator.add("order.banner.top.alt", "Order update image", "emails", "en");
    Translator.add("order.body.center", "We have processed your order. The final price is %total_price%. <br \/><br \/>\nThanks and we hope to see you back soon...<br \/><br \/>\n", "emails", "en");
    Translator.add("order.button", "Check your orders here", "emails", "en");
    Translator.add("resetting.email.subject", "Reset your password @ k-lighting.com", "emails", "en");
    Translator.add("resetting.email.message", "Hi, %name%!<br \/><br \/>\nTo reset your password, please click <a href=\"%confirmationUrl%\" target=\"_blank\">here<\/a><br \/><br \/>\nBest Regards,<br \/>\nK-Lighting\n", "emails", "en");
})(Translator);
