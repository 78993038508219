(function (Translator) {
    // pt
    Translator.add("welcome.subject", "A sua conta foi aprovada. Por favor confirme o seu email | K-Lighting", "emails", "pt");
    Translator.add("welcome.body.top", "Ola, %name%! Temos o prazer de informar que o seu registo foi validado . Para terminar este processo por favor confirme a sua conta.", "emails", "pt");
    Translator.add("welcome.body.center", "<p>Aqui voce pode encontrar todas as nossas novidades e optimas oportunidades!<br \/> Pode tambem fazer o download dos nossos catalogos e Tabelas de Pre\u00e7os, tambem pode fazer a encomenda atravez do nosso site.<\/p><p>\u00c9 nitido como os nossos cristais!<\/p>", "emails", "pt");
    Translator.add("welcome.activate.button", "Clicar para activar a conta", "emails", "pt");
    Translator.add("welcome.body.know_more", "A sua conta foi aprovada", "emails", "pt");
    Translator.add("welcome.banner.top.alt", "Bem-vinda \u00e0 K-lighting by Candibambu", "emails", "pt");
    Translator.add("order.subject", "Actualiza\u00e7ao na encomenda n. %number% em K-lighting.com", "emails", "pt");
    Translator.add("order.header", "Actualiza\u00e7ao na encomenda  n. %number%", "emails", "pt");
    Translator.add("order.sub_header", "Loja K-Lighting.com", "emails", "pt");
    Translator.add("order.banner.top.alt", "Banner do email de nova encomenda", "emails", "pt");
    Translator.add("order.body.center", "Validamos a vossa encomenda o pre\u00e7o final \u00e9 %total_price%. <br \/><br \/>\nObrigado e esperamos voltar a receber noticias vossas em breve...<br \/><br \/>\n", "emails", "pt");
    Translator.add("order.button", "Ver a sua encomenda aqui", "emails", "pt");
    Translator.add("resetting.email.subject", "Recupera\u00e7\u00e3o de password @ k-lighting.com", "emails", "pt");
    Translator.add("resetting.email.message", "Ola, %name%!<br \/><br \/>\nPara recuperar a sua password, por favor click <a href=\"%confirmationUrl%\" target=\"_blank\">neste link<\/a><br \/><br \/>\nAtenciosamente,<br \/>\nK-Lighting\n", "emails", "pt");
    Translator.add("contact_us.subject", "Foi efectuado um novo pedido atraves do site", "emails", "pt");
    Translator.add("contact_us.body.header", "Detalhes:", "emails", "pt");
    Translator.add("contact_us.body.part_1", "Nome: <b>%name%<\/b> <br \/> Telefone: <b>%phone%<\/b> <br \/> Email <b>%email%<\/b> <br \/>", "emails", "pt");
    Translator.add("contact_us.body.part_2", "Messagem: <br \/>", "emails", "pt");
    Translator.add("welcome.warning.subject", "Novo utilizador %email% registrado em K-lighting.com", "emails", "pt");
    Translator.add("welcome.warning.new", "Novo utilizador registrado em K-lighting.com", "emails", "pt");
    Translator.add("welcome.warning.button", "Rever utilizador", "emails", "pt");
    Translator.add("welcome.warning.user.email", "Email", "emails", "pt");
    Translator.add("welcome.warning.user.first_name", "Primeiro nome", "emails", "pt");
    Translator.add("welcome.warning.user.last_name", "Ultimo nome", "emails", "pt");
    Translator.add("new_order.warning.subject", "Nova encomenda do email %email% em K-lighting.com", "emails", "pt");
    Translator.add("new_order.warning.new", "Nova encomenda em K-lighting.com", "emails", "pt");
    Translator.add("new_order.warning.email", "Email:", "emails", "pt");
    Translator.add("new_order.warning.amount", "Total", "emails", "pt");
    Translator.add("new_order.warning.button", "Ver encomenda!", "emails", "pt");
})(Translator);
