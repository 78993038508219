(function (Translator) {
    // en
    Translator.add("modal.flash_message.title", "Importante information", "FlashMessages", "en");
    Translator.add("modal.default.close", "Close", "FlashMessages", "en");
    Translator.add("contact_us.success", "Thanks for your message. We'll answer as soon as possible", "FlashMessages", "en");
    Translator.add("company_edit.success", "Company information changed", "FlashMessages", "en");
    Translator.add("shopping.checkout.error", "Something went wrong. Please try again.", "FlashMessages", "en");
    Translator.add("shopping.checkout.no_items", "Your cart is empty!", "FlashMessages", "en");
    Translator.add("search.error", "Something went wrong. Please try to search again.", "FlashMessages", "en");
    Translator.add("product.add.success", "Product added!", "FlashMessages", "en");
    Translator.add("product.add.fail", "Error adding product. Please try again later...", "FlashMessages", "en");
    Translator.add("product.edit.success", "Product quantity changed!", "FlashMessages", "en");
    Translator.add("product.edit.fail", "Error changing product quantity. Please try again later...", "FlashMessages", "en");
    Translator.add("product.delete.success", "Product deleted!", "FlashMessages", "en");
    Translator.add("product.delete.fail", "Error removing product. Please try again later...", "FlashMessages", "en");
    Translator.add("profile.updated", "Profile Updated", "FlashMessages", "en");
    Translator.add("password_changed", "Password as been changed", "FlashMessages", "en");
})(Translator);
