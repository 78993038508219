(function (Translator) {
    // pt
    Translator.add("action_edit", "Editar", "admin", "pt");
    Translator.add("action_delete", "Apagar", "admin", "pt");
    Translator.add("link_filters", "Filtrar", "admin", "pt");
    Translator.add("filter.label_sku", "Referencia", "admin", "pt");
    Translator.add("admin.administration", "Administra\u00e7\u00e3o", "admin", "pt");
    Translator.add("admin.auto_vat_validation", "Auto-Approve", "admin", "pt");
    Translator.add("admin.cms_content", "Conte\u00fado CMS", "admin", "pt");
    Translator.add("admin.cms_gallery", "Imagens CMS", "admin", "pt");
    Translator.add("admin.gallery.homepage", "Homepage Gallery", "admin", "pt");
    Translator.add("admin.faqs", "FAQs", "admin", "pt");
    Translator.add("admin.info", "Dados", "admin", "pt");
    Translator.add("admin.manager", "Gestor", "admin", "pt");
    Translator.add("admin.others", "Outros", "admin", "pt");
    Translator.add("admin.orders", "Encomendas Online", "admin", "pt");
    Translator.add("admin.picture", "Imagem", "admin", "pt");
    Translator.add("admin.products_and_categories", "Produtos & Categorias", "admin", "pt");
    Translator.add("admin.profile", "Perfil", "admin", "pt");
    Translator.add("admin.price_list", "Tabelas de Pre\u00e7os", "admin", "pt");
    Translator.add("admin.price_list_list", "Editar Tabelas", "admin", "pt");
    Translator.add("admin.price_list_download", "Download Tabelas de Pre\u00e7os", "admin", "pt");
    Translator.add("admin.seo_pages", "P\u00e1ginas SEO", "admin", "pt");
    Translator.add("admin.user", "Utilizador", "admin", "pt");
    Translator.add("admin.users", "Utilizadores", "admin", "pt");
    Translator.add("allergens", "Alerg\u00e9nios", "admin", "pt");
    Translator.add("amount", "Quantidade", "admin", "pt");
    Translator.add("attachments", "Anexos", "admin", "pt");
    Translator.add("blog", "Blog", "admin", "pt");
    Translator.add("breadcrumb.link_advance_configuration_list", "Configura\u00e7\u00f5es Avan\u00e7adas", "admin", "pt");
    Translator.add("breadcrumb.link_blog_category_list", "Categorias do Blog", "admin", "pt");
    Translator.add("breadcrumb.link_blog_list", "Publica\u00e7\u00f5es do Blog", "admin", "pt");
    Translator.add("breadcrumb.link_category_list", "Categorias", "admin", "pt");
    Translator.add("breadcrumb.link_cms_page_list", "P\u00e1ginas CMS", "admin", "pt");
    Translator.add("breadcrumb.link_f_a_q_category_list", "Categorias de FAQs", "admin", "pt");
    Translator.add("breadcrumb.link_f_a_q_list", "FAQs", "admin", "pt");
    Translator.add("breadcrumb.link_gallery_list", "Galerias", "admin", "pt");
    Translator.add("breadcrumb.link_partnership_list", "Parcerias", "admin", "pt");
    Translator.add("breadcrumb.link_product_list", "Produtos", "admin", "pt");
    Translator.add("breadcrumb.link_product_category_list", "Categoria de Produtos", "admin", "pt");
    Translator.add("breadcrumb.link_promotion_list", "Promo\u00e7\u00f5es", "admin", "pt");
    Translator.add("breadcrumb.link_review_list", "Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("breadcrumb.link_seo_page_list", "P\u00e1ginas SEO", "admin", "pt");
    Translator.add("breadcrumb.link_store_list", "Lojas", "admin", "pt");
    Translator.add("breadcrumb.link_order_list", "Encomendas online", "admin", "pt");
    Translator.add("button.add", "Adicionar", "admin", "pt");
    Translator.add("button.browse", "Procurar", "admin", "pt");
    Translator.add("button.delete.question", "Remover?", "admin", "pt");
    Translator.add("button.list", "Listar", "admin", "pt");
    Translator.add("btn.update_send_and_return_to_list", "Actualizar, Email e Voltar a lista", "admin", "pt");
    Translator.add("category", "Categoria", "admin", "pt");
    Translator.add("category_info", "Dados da Categoria", "admin", "pt");
    Translator.add("default", "Geral", "admin", "pt");
    Translator.add("default.img.alt", "Imagem", "admin", "pt");
    Translator.add("depth", "Profundidade (cm)", "admin", "pt");
    Translator.add("download_qr_code", "Download QR-Code", "admin", "pt");
    Translator.add("error.lang_price_list", "Por favor selecione a lingua e a lista de pre\u00e7os", "admin", "pt");
    Translator.add("filter.label_ambience", "Ambiente", "admin", "pt");
    Translator.add("filter.label_category", "Categoria", "admin", "pt");
    Translator.add("filter.label_created_at", "Criado em", "admin", "pt");
    Translator.add("filter.label_email", "Email", "admin", "pt");
    Translator.add("filter.label_enabled", "Activo", "admin", "pt");
    Translator.add("filter.label_external_key", "Chave externa", "admin", "pt");
    Translator.add("filter.label_homepage", "P\u00e1gina Inicial", "admin", "pt");
    Translator.add("filter.label_id", "ID", "admin", "pt");
    Translator.add("filter.label_name", "Nome", "admin", "pt");
    Translator.add("filter.label_overall", "Nota", "admin", "pt");
    Translator.add("filter.label_position", "Posi\u00e7\u00e3o", "admin", "pt");
    Translator.add("filter.label_price", "Pre\u00e7o", "admin", "pt");
    Translator.add("filter.label_product", "Produto", "admin", "pt");
    Translator.add("filter.label_quality", "Qualidade", "admin", "pt");
    Translator.add("filter.label_store", "Loja", "admin", "pt");
    Translator.add("filter.label_translations_title", "T\u00edtulo", "admin", "pt");
    Translator.add("filter.label_treatment", "Tratamento", "admin", "pt");
    Translator.add("filter.label_type", "Tipo", "admin", "pt");
    Translator.add("form.blog.details", "Detalhes do Blog", "admin", "pt");
    Translator.add("form.extra", "Extra", "admin", "pt");
    Translator.add("form.fieldset.label_general", "Geral", "admin", "pt");
    Translator.add("form.select", "Selecione uma op\u00e7ao", "admin", "pt");
    Translator.add("form.label_answer", "Resposta", "admin", "pt");
    Translator.add("form.label_amount", "Pre\u00e7o", "admin", "pt");
    Translator.add("form.label_category", "Categoria", "admin", "pt");
    Translator.add("form.label_content", "Conte\u00fado", "admin", "pt");
    Translator.add("form.label_content_formatter", "Formata\u00e7\u00e3o do conte\u00fado", "admin", "pt");
    Translator.add("form.label_credentials_expired", "Credenciais Expiradas", "admin", "pt");
    Translator.add("form.label_description", "Descri\u00e7\u00e3o", "admin", "pt");
    Translator.add("form.label_duration", "Dura\u00e7\u00e3o", "admin", "pt");
    Translator.add("form.label_email", "Email", "admin", "pt");
    Translator.add("form.label_enabled", "Activo", "admin", "pt");
    Translator.add("form.label_expired", "Expirado", "admin", "pt");
    Translator.add("form.label_faq_category", "Categorias de FAQs", "admin", "pt");
    Translator.add("form.label_gallery", "Galeria de Imagens", "admin", "pt");
    Translator.add("form.label_gallery_has_medias", "Imagens", "admin", "pt");
    Translator.add("form.label_gender", "G\u00e9nero", "admin", "pt");
    Translator.add("form.label_homepage", "P\u00e1gina Inicial", "admin", "pt");
    Translator.add("form.label_keywords", "Palavras-chave", "admin", "pt");
    Translator.add("form.label_super_admin", "\u00c9 Administrador do Site?", "admin", "pt");
    Translator.add("form.label_locked", "Bloqueado", "admin", "pt");
    Translator.add("form.label_manual_url", "URL e t\u00edtulo manuais", "admin", "pt");
    Translator.add("form.label_media", "Imagem", "admin", "pt");
    Translator.add("form.label_mobile", "Telem\u00f3vel", "admin", "pt");
    Translator.add("form.label_name", "Nome", "admin", "pt");
    Translator.add("form.label_parent", "Pai", "admin", "pt");
    Translator.add("form.label_phone", "Telefone", "admin", "pt");
    Translator.add("form.label_picture", "Imagem", "admin", "pt");
    Translator.add("form.label_question", "Pergunta", "admin", "pt");
    Translator.add("form.label_position", "Posi\u00e7\u00e3o", "admin", "pt");
    Translator.add("form.label_price", "Pre\u00e7o", "admin", "pt");
    Translator.add("form.label_sub_title", "Sub-t\u00edtulo", "admin", "pt");
    Translator.add("form.label_sku", "C\u00f3digo unit\u00e1rio", "admin", "pt");
    Translator.add("form.label_title", "Tit\u00falo", "admin", "pt");
    Translator.add("form.label_type", "Tipo", "admin", "pt");
    Translator.add("form.label_url", "URL", "admin", "pt");
    Translator.add("form.label_language", "Lingua", "admin", "pt");
    Translator.add("form.label_price_list", "Tabela de pre\u00e7os", "admin", "pt");
    Translator.add("form_label.description", "Transporte, Desconto, etc...", "admin", "pt");
    Translator.add("form_label.amount", "Valor e.g: -10 ou 352.33", "admin", "pt");
    Translator.add("form.label_plain_password", "Password", "admin", "pt");
    Translator.add("form.label_lastname", "Apelido", "admin", "pt");
    Translator.add("form.label_firstname", "Primeiro Nome", "admin", "pt");
    Translator.add("form.seo_details", "Detalhes de SEO", "admin", "pt");
    Translator.add("form.address.address_line1", "Endere\u00e7o (Linha 1)", "admin", "pt");
    Translator.add("form.address.address_line2", "Endere\u00e7o (Linha 2)", "admin", "pt");
    Translator.add("form.address.address_line_1", "Endere\u00e7o (Linha 1)", "admin", "pt");
    Translator.add("form.address.address_line_2", "Endere\u00e7o (Linha 2)", "admin", "pt");
    Translator.add("form.address.postal_code", "C\u00f3digo Postal", "admin", "pt");
    Translator.add("form.address.locality", "Localidade", "admin", "pt");
    Translator.add("form.address.city", "Cidade", "admin", "pt");
    Translator.add("form.postal_code", "C\u00f3digo Postal", "admin", "pt");
    Translator.add("form.price_list", "Tabela de pre\u00e7os", "admin", "pt");
    Translator.add("form.label_pdf_excluded", "Excluir da Tabela de pre\u00e7os ?", "admin", "pt");
    Translator.add("form.label_opening_hours_start", "Hor\u00e1rio de abertura", "admin", "pt");
    Translator.add("form.label_opening_hours_close", "Hor\u00e1rio de fecho", "admin", "pt");
    Translator.add("form.label_company__is_verified", "Aprovado?", "admin", "pt");
    Translator.add("form_page.faq", "FAQ", "admin", "pt");
    Translator.add("form_page.group_seo_label", "P\u00e1gina SEO", "admin", "pt");
    Translator.add("gross_weight", "Peso Bruto", "admin", "pt");
    Translator.add("height", "Altura (cm)", "admin", "pt");
    Translator.add("homepage", "P\u00e1gina Inicial", "admin", "pt");
    Translator.add("list.label_number", "Numero", "admin", "pt");
    Translator.add("list.label_total", "Total", "admin", "pt");
    Translator.add("list.label_download", "Download", "admin", "pt");
    Translator.add("list.label_state", "Estado do pedido", "admin", "pt");
    Translator.add("list.label_date", "Data", "admin", "pt");
    Translator.add("list.label__action", "Ac\u00e7\u00e3o", "admin", "pt");
    Translator.add("list.label_action", "Ac\u00e7\u00e3o", "admin", "pt");
    Translator.add("list.label_ambience", "Ambiente", "admin", "pt");
    Translator.add("list.label_ambience_score", "Ambiente (1~5)", "admin", "pt");
    Translator.add("list.label_answer", "Resposta", "admin", "pt");
    Translator.add("list.label_category", "Categoria", "admin", "pt");
    Translator.add("list.label_created_at", "Criado em", "admin", "pt");
    Translator.add("list.label_description", "Descri\u00e7\u00e3o", "admin", "pt");
    Translator.add("list.label_duration", "Dura\u00e7\u00e3o", "admin", "pt");
    Translator.add("list.label_email", "Email", "admin", "pt");
    Translator.add("list.label_enabled", "Activo", "admin", "pt");
    Translator.add("list.label_external_key", "Chave externa", "admin", "pt");
    Translator.add("list.label_faq_category", "Categoria das FAQs", "admin", "pt");
    Translator.add("list.label_groups", "Grupos", "admin", "pt");
    Translator.add("list.label_homepage", "P\u00e1gina Inicial", "admin", "pt");
    Translator.add("list.label_id", "ID", "admin", "pt");
    Translator.add("list.label_impersonating", "Personificar", "admin", "pt");
    Translator.add("list.label_key", "Chave", "admin", "pt");
    Translator.add("list.label_keywords", "Palavras-Chave", "admin", "pt");
    Translator.add("list.label_locked", "Bloqueado", "admin", "pt");
    Translator.add("list.label_mobile", "Telem\u00f3vel", "admin", "pt");
    Translator.add("list.label_name", "Nome", "admin", "pt");
    Translator.add("list.label_overall", "Nota", "admin", "pt");
    Translator.add("list.label_overall_score", "Nota (1~5)", "admin", "pt");
    Translator.add("list.label_parent", "Pai", "admin", "pt");
    Translator.add("list.label_phone", "Telefone", "admin", "pt");
    Translator.add("list.label_position", "Posi\u00e7\u00e3o", "admin", "pt");
    Translator.add("list.label_price", "Pre\u00e7o", "admin", "pt");
    Translator.add("list.label_price_score", "Price (1~5)", "admin", "pt");
    Translator.add("list.label_product", "Produto", "admin", "pt");
    Translator.add("list.label_quality", "Qualidade", "admin", "pt");
    Translator.add("list.label_quality_score", "Qualidade (1~5)", "admin", "pt");
    Translator.add("list.label_question", "Pergunta", "admin", "pt");
    Translator.add("list.label_translations_price", "Pre\u00e7o", "admin", "pt");
    Translator.add("list.label_seo_page_url", "URL da p\u00e1gina SEO", "admin", "pt");
    Translator.add("list.label_start_date", "Data In\u00edcio", "admin", "pt");
    Translator.add("list.label_end_date", "Data Fim", "admin", "pt");
    Translator.add("list.label_status", "Estado", "admin", "pt");
    Translator.add("list.label_store", "Loja", "admin", "pt");
    Translator.add("list.label_title", "T\u00edtulo", "admin", "pt");
    Translator.add("list.label_translations_summary", "Resumo", "admin", "pt");
    Translator.add("list.label_translations_title", "T\u00edtulo", "admin", "pt");
    Translator.add("list.label_treatment", "Tratamento", "admin", "pt");
    Translator.add("list.label_treatment_score", "Tratamento (1~5)", "admin", "pt");
    Translator.add("list.label_type", "Tipo", "admin", "pt");
    Translator.add("list.label_url", "URL", "admin", "pt");
    Translator.add("list.label_user", "Utilizador", "admin", "pt");
    Translator.add("list.label_value", "Valor", "admin", "pt");
    Translator.add("locale.select.all", "Todos", "admin", "pt");
    Translator.add("message_form_group_empty", "SEM DADOS", "admin", "pt");
    Translator.add("name", "Nome", "admin", "pt");
    Translator.add("net_weight", "Peso L\u00edquido", "admin", "pt");
    Translator.add("no_data", "N\/A", "admin", "pt");
    Translator.add("nutritional_values", "Valores Nutricionais", "admin", "pt");
    Translator.add("order", "Encomendas", "admin", "pt");
    Translator.add("partnership", "Parcerias", "admin", "pt");
    Translator.add("placeholder.no_media", "Sem Imagem", "admin", "pt");
    Translator.add("placeholder.price_list.default", "Escolha a tabela", "admin", "pt");
    Translator.add("placeholder.language.default", "escolha a lingua", "admin", "pt");
    Translator.add("placeholder.company.vat_country", "Pa\u00eds de registo", "admin", "pt");
    Translator.add("product", "Produtos", "admin", "pt");
    Translator.add("price", "Pre\u00e7o", "admin", "pt");
    Translator.add("price_increment", "Incremento de Pre\u00e7o", "admin", "pt");
    Translator.add("price_list.download", "Download tabela de pre\u00e7os", "admin", "pt");
    Translator.add("price_list.download_admin", "Download Tabela Admin", "admin", "pt");
    Translator.add("price_list.delete", "Gerar novas Tabelas", "admin", "pt");
    Translator.add("product.packages", "Pacotes", "admin", "pt");
    Translator.add("product.price_lists", "Listas de Pre\u00e7os", "admin", "pt");
    Translator.add("product.relations", "Produtos Relacionados", "admin", "pt");
    Translator.add("product.suppliers", "Suppliers", "admin", "pt");
    Translator.add("product.variants", "Variantes", "admin", "pt");
    Translator.add("products_and_categories", "Produtos & Categorias", "admin", "pt");
    Translator.add("product_category", "Categorias dos Produtos", "admin", "pt");
    Translator.add("product_info", "Dados do Produto", "admin", "pt");
    Translator.add("product_pictures", "Imagens do Produto", "admin", "pt");
    Translator.add("proforma_item.search_product", "Pesquisar Produto", "admin", "pt");
    Translator.add("promotion", "Promo\u00e7\u00f5es", "admin", "pt");
    Translator.add("quantity", "Quantidade", "admin", "pt");
    Translator.add("recent_blog_posts", "Recentes publica\u00e7\u00f5es no Blog", "admin", "pt");
    Translator.add("recent_reviews", "Recentes Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("related", "Relacionar com", "admin", "pt");
    Translator.add("reviews", "Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("reviews_average", "M\u00e9dia das Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("search.address", "Procurar aqui", "admin", "pt");
    Translator.add("seo", "SEO", "admin", "pt");
    Translator.add("seo_page", "SEO", "admin", "pt");
    Translator.add("seo_page.description", "Descri\u00e7\u00e3o da P\u00e1gina", "admin", "pt");
    Translator.add("seo.description", "Descri\u00e7\u00e3o da P\u00e1gina", "admin", "pt");
    Translator.add("seo_page.keywords", "Palavras-chave da p\u00e1gina", "admin", "pt");
    Translator.add("seo.keywords", "Palavras-chave da p\u00e1gina", "admin", "pt");
    Translator.add("seo_page.manual", "URL e t\u00edtulo manuais", "admin", "pt");
    Translator.add("seo.manual", "URL e t\u00edtulo manuais", "admin", "pt");
    Translator.add("seo_page.title", "T\u00edtulo da p\u00e1gina", "admin", "pt");
    Translator.add("seo_page.url", "URL da p\u00e1gina", "admin", "pt");
    Translator.add("show.label_ambience", "Ambiente (1 ~ 5)", "admin", "pt");
    Translator.add("show.label_id", "ID", "admin", "pt");
    Translator.add("show.label_overall", "Nota", "admin", "pt");
    Translator.add("show.label_price", "Pre\u00e7o (1 ~ 5)", "admin", "pt");
    Translator.add("show.label_product", "Produto", "admin", "pt");
    Translator.add("show.label_quality", "Qualidade (1 ~ 5)", "admin", "pt");
    Translator.add("show.label_store", "Loja", "admin", "pt");
    Translator.add("show.label_treatment", "Tratamento (1 ~ 5)", "admin", "pt");
    Translator.add("show.label_note", "Obs.", "admin", "pt");
    Translator.add("side_menu.advanced_configurations", "Configura\u00e7\u00f5es Avan\u00e7adas", "admin", "pt");
    Translator.add("side_menu.blog", "Publica\u00e7\u00f5es do Blog", "admin", "pt");
    Translator.add("side_menu.content", "Conteudo", "admin", "pt");
    Translator.add("side_menu.dynamic_page", "Conteudo", "admin", "pt");
    Translator.add("side_menu.dynamic_page_category", "Categorias do Conteudo", "admin", "pt");
    Translator.add("side_menu.blog_category", "Categorias do Blog", "admin", "pt");
    Translator.add("side_menu.categories", "Categorias", "admin", "pt");
    Translator.add("side_menu.cms_galleries", "Galerias de CMS", "admin", "pt");
    Translator.add("side_menu.cms_pages", "P\u00e1ginas de CMS", "admin", "pt");
    Translator.add("side_menu.collections", "Colec\u00e7\u00f5es", "admin", "pt");
    Translator.add("side_menu.faq_categories", "Categorias das FAQs", "admin", "pt");
    Translator.add("side_menu.faqs", "FAQs", "admin", "pt");
    Translator.add("side_menu.payment", "Pagamentos", "admin", "pt");
    Translator.add("side_menu.products", "Produtos", "admin", "pt");
    Translator.add("side_menu.seo", "P\u00e1ginas de SEO", "admin", "pt");
    Translator.add("side_menu.users.add_new", "Adicionar", "admin", "pt");
    Translator.add("side_menu.users.approve", "Aprovar", "admin", "pt");
    Translator.add("side_menu.users.all", "Todos", "admin", "pt");
    Translator.add("store", "Lojas", "admin", "pt");
    Translator.add("store.info", "Dados da Loja", "admin", "pt");
    Translator.add("store.location", "Localiza\u00e7\u00e3o da Loja", "admin", "pt");
    Translator.add("stores", "Lojas", "admin", "pt");
    Translator.add("subTitle", "Sub-t\u00edtulo", "admin", "pt");
    Translator.add("title", "T\u00edtulo", "admin", "pt");
    Translator.add("total", "Total", "admin", "pt");
    Translator.add("total_facebook_users", "Total de Utilizadores do Facebook", "admin", "pt");
    Translator.add("total_google_users", "Total de Utilizadores do Google+", "admin", "pt");
    Translator.add("total_reviews", "Total de Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("type", "Tipo", "admin", "pt");
    Translator.add("url", "URL", "admin", "pt");
    Translator.add("users", "Utilizadores", "admin", "pt");
    Translator.add("view_all_blog_entries", "Ver todas as publica\u00e7\u00f5es do Blog", "admin", "pt");
    Translator.add("view_all_reviews", "Ver todas as Avalia\u00e7\u00f5es", "admin", "pt");
    Translator.add("width", "Largura (cm)", "admin", "pt");
    Translator.add("form.label_context_menu", "Aparece no menu laterar ?", "admin", "pt");
    Translator.add("form.label_image_top", "Imagem no topo do artigo", "admin", "pt");
    Translator.add("placeholder.media.description", "Descri\u00e7\u00e3o da Imagem", "admin", "pt");
    Translator.add("form.attachments", "Ficheiros em anexos na Pagina", "admin", "pt");
    Translator.add("form.label_attachments", "Lista de ficheiros", "admin", "pt");
    Translator.add("form.dynamic_page.details", "Detalhes", "admin", "pt");
    Translator.add("help.dynamic.vars", "a parte do url {category} \u00e9 substituido pelo slug da categoria", "admin", "pt");
    Translator.add("placeholder.please_select_a_category", "Selecione a categoria", "admin", "pt");
    Translator.add("action_download", "Download PDF", "admin", "pt");
    Translator.add("list.label_company_name", "Nome da empresa", "admin", "pt");
    Translator.add("product.search", "Procurar Produto", "admin", "pt");
    Translator.add("form.label_company_name", "Nome da empresa", "admin", "pt");
    Translator.add("form.label_addressLine1", "Morada", "admin", "pt");
    Translator.add("form.label_postalCode", "Codigo de Postal", "admin", "pt");
    Translator.add("form.label_city", "Cidade", "admin", "pt");
    Translator.add("form.label_locality", "Localidade", "admin", "pt");
    Translator.add("form.label_country", "Pa\u00eds", "admin", "pt");
})(Translator);
