(function (Translator) {
    // pt
    Translator.add("about_us", "Sobre n\u00f3s", "messages", "pt");
    Translator.add("add", "Adicionar", "messages", "pt");
    Translator.add("address", "Endere\u00e7o", "messages", "pt");
    Translator.add("attachment.label.description", "Descri\u00e7\u00e3o", "messages", "pt");
    Translator.add("add_to_cart", "Adicionar ao carrinho", "messages", "pt");
    Translator.add("back", "Costas", "messages", "pt");
    Translator.add("dynamic_page", "Not\u00edcia", "messages", "pt");
    Translator.add("dynamic_page.no_results", "Nenhum resultado encontrado.", "messages", "pt");
    Translator.add("dynamic_page.search_again", "Por favor, tente outra pesquisa", "messages", "pt");
    Translator.add("dynamic_page.menu.categories", "Categorias", "messages", "pt");
    Translator.add("menu.categories", "Categorias", "messages", "pt");
    Translator.add("dynamic_page.menu.latest", "Posts recentes", "messages", "pt");
    Translator.add("dynamic_page.more_info", "+ info", "messages", "pt");
    Translator.add("dynamic_page.posts", "Postagens recentes", "messages", "pt");
    Translator.add("browse", "Procurar", "messages", "pt");
    Translator.add("button.back", "Voltar", "messages", "pt");
    Translator.add("button.registration", "Registo", "messages", "pt");
    Translator.add("button.save", "Guardar", "messages", "pt");
    Translator.add("cancel", "Cancelar", "messages", "pt");
    Translator.add("categories", "Categorias", "messages", "pt");
    Translator.add("change", "mudan\u00e7a", "messages", "pt");
    Translator.add("close", "Fechar", "messages", "pt");
    Translator.add("company", "Empresa", "messages", "pt");
    Translator.add("company.header.main_address", "Endere\u00e7o principal", "messages", "pt");
    Translator.add("company_name", "Nome da empresa", "messages", "pt");
    Translator.add("company_vat_country", "Pa\u00eds IVA", "messages", "pt");
    Translator.add("company_vat_number", "N\u00famero de IVA", "messages", "pt");
    Translator.add("contact_us", "Contate-Nos", "messages", "pt");
    Translator.add("contact_us.subject.1", "Quero algumas informa\u00e7\u00f5es", "messages", "pt");
    Translator.add("contact_us.title", "N\u00f3s esperamos seu contato", "messages", "pt");
    Translator.add("contacts", "Contatos", "messages", "pt");
    Translator.add("country.vat.at", "\u00c1ustria", "messages", "pt");
    Translator.add("country.vat.be", "B\u00e9lgica", "messages", "pt");
    Translator.add("country.vat.bg", "Bulg\u00e1ria", "messages", "pt");
    Translator.add("country.vat.cy", "Chipre", "messages", "pt");
    Translator.add("country.vat.cz", "Rep\u00fablica Checa", "messages", "pt");
    Translator.add("country.vat.dk", "Dinamarca", "messages", "pt");
    Translator.add("country.vat.ee", "Est\u00f4nia", "messages", "pt");
    Translator.add("country.vat.fi", "Filand", "messages", "pt");
    Translator.add("country.vat.fr", "Fran\u00e7a", "messages", "pt");
    Translator.add("country.vat.de", "Alemanha", "messages", "pt");
    Translator.add("country.vat.el", "Gr\u00e9cia", "messages", "pt");
    Translator.add("country.vat.hu", "Hungria", "messages", "pt");
    Translator.add("country.vat.ie", "Irlanda", "messages", "pt");
    Translator.add("country.vat.it", "It\u00e1lia", "messages", "pt");
    Translator.add("country.vat.lv", "Let\u00f4nia", "messages", "pt");
    Translator.add("country.vat.lt", "Litu\u00e2nia", "messages", "pt");
    Translator.add("country.vat.lu", "Luxemburgo", "messages", "pt");
    Translator.add("country.vat.mt", "Malta", "messages", "pt");
    Translator.add("country.vat.nl", "Pa\u00edses Baixos", "messages", "pt");
    Translator.add("country.vat.ot", "Outro", "messages", "pt");
    Translator.add("country.vat.pl", "Pol\u00f4nia", "messages", "pt");
    Translator.add("country.vat.pt", "Portugal", "messages", "pt");
    Translator.add("country.vat.ro", "Rom\u00e9nia", "messages", "pt");
    Translator.add("country.vat.sk", "Eslov\u00e1quia", "messages", "pt");
    Translator.add("country.vat.si", "Eslov\u00eania", "messages", "pt");
    Translator.add("country.vat.es", "Espanha", "messages", "pt");
    Translator.add("country.vat.se", "Su\u00e9cia", "messages", "pt");
    Translator.add("country.vat.gb", "Reino Unido", "messages", "pt");
    Translator.add("country.vat.zz.other", "Fora da Uni\u00e3o Europeia", "messages", "pt");
    Translator.add("date_of_birth", "Data de nascimento", "messages", "pt");
    Translator.add("date_string", "y% de% m\u00eas%,% ano%", "messages", "pt");
    Translator.add("default_currency_symbol", "\u20ac", "messages", "pt");
    Translator.add("delete", "Excluir", "messages", "pt");
    Translator.add("description", "Descri\u00e7\u00e3o", "messages", "pt");
    Translator.add("do_order", "Confirmar pedido", "messages", "pt");
    Translator.add("download", "Download", "messages", "pt");
    Translator.add("DIALOGS_OK", "OK", "messages", "pt");
    Translator.add("email", "O email", "messages", "pt");
    Translator.add("error", "Erro", "messages", "pt");
    Translator.add("faqs", "FAQS", "messages", "pt");
    Translator.add("faqs.title", "perguntas frequentes", "messages", "pt");
    Translator.add("first_name", "Primeiro nome", "messages", "pt");
    Translator.add("footer.top.message", "Voc\u00ea precisa de ajuda", "messages", "pt");
    Translator.add("form.address.address_line_1", "linha de endere\u00e7o", "messages", "pt");
    Translator.add("form.address.address_line_2", "linha de endere\u00e7o (opcional)", "messages", "pt");
    Translator.add("form.address.postal_code", "C\u00f3digo postal", "messages", "pt");
    Translator.add("form.address.locality", "localidade", "messages", "pt");
    Translator.add("form.address.city", "cidade", "messages", "pt");
    Translator.add("form.address.country", "pa\u00eds", "messages", "pt");
    Translator.add("form_email_example", "(ex: geral@candibambu.pt)", "messages", "pt");
    Translator.add("full_name", "nome completo", "messages", "pt");
    Translator.add("gender", "G\u00eanero", "messages", "pt");
    Translator.add("gender_female", "F\u00eamea", "messages", "pt");
    Translator.add("gender_male", "Masculino", "messages", "pt");
    Translator.add("get_connected", "Conecte-se", "messages", "pt");
    Translator.add("home", "Inicio", "messages", "pt");
    Translator.add("homepage", "Pagina inicial", "messages", "pt");
    Translator.add("homepage.title", "Mais do que ilumina\u00e7\u00e3o ou decora\u00e7\u00e3o d\u00e1-te bem estar", "messages", "pt");
    Translator.add("id", "identidade", "messages", "pt");
    Translator.add("icon.flow.register", "Crie a sua conta aqui", "messages", "pt");
    Translator.add("icon.flow.email_confirmation", "A conta ser\u00e1 validada", "messages", "pt");
    Translator.add("icon.flow.verified", "Voc\u00ea pode ver toda a informa\u00e7\u00e3o", "messages", "pt");
    Translator.add("last_name", "\u00daltimo nome", "messages", "pt");
    Translator.add("language.en", "Ingl\u00eas", "messages", "pt");
    Translator.add("language.es", "Espanhol", "messages", "pt");
    Translator.add("language.fr", "Franc\u00eas", "messages", "pt");
    Translator.add("language.pt", "Portugu\u00eas", "messages", "pt");
    Translator.add("limit_2MB", "2 MB LIMIT", "messages", "pt");
    Translator.add("limit_4MB", "4MB Max", "messages", "pt");
    Translator.add("file_upload", "Anexar ficheiro", "messages", "pt");
    Translator.add("location", "Localiza\u00e7\u00e3o", "messages", "pt");
    Translator.add("login", "Entrar", "messages", "pt");
    Translator.add("login.header", "BEM-VINDO \u00c0 K-LIGHTING", "messages", "pt");
    Translator.add("login.forgot_password", "Esqueceu a senha?", "messages", "pt");
    Translator.add("login.register", "Crie a sua conta aqui", "messages", "pt");
    Translator.add("message", "mensagem", "messages", "pt");
    Translator.add("mobile", "Telem\u00f3vel", "messages", "pt");
    Translator.add("more_details", "Mais detalhes", "messages", "pt");
    Translator.add("name", "Nome", "messages", "pt");
    Translator.add("newsletter.registration.success.header", "Inscri\u00e7\u00e3o na newsletter", "messages", "pt");
    Translator.add("newsletter.registration.success.body", "De agora em diante, receba as nossas \u00faltimas not\u00edcias. Obrigado pela sua subscri\u00e7\u00e3o!", "messages", "pt");
    Translator.add("no-image", "Sem imagem do produto", "messages", "pt");
    Translator.add("order", "Encomenda", "messages", "pt");
    Translator.add("order.info", "Encomenda Online", "messages", "pt");
    Translator.add("order.disclaimer.before", "Obteremos o custo do transporte para o seu pedido e inform\u00e1-lo sobre o custo, uma vez que obtenhamos o melhor pre\u00e7o.", "messages", "pt");
    Translator.add("order.disclaimer", "Atualmente, estamos trabalhando para obter o custo de transporte para o seu pedido. N\u00f3s informaremos sobre o custo, uma vez que obtenhamos o melhor pre\u00e7o.", "messages", "pt");
    Translator.add("order.from", "A partir de", "messages", "pt");
    Translator.add("order.email", "O email", "messages", "pt");
    Translator.add("order.number", "Encomenda n.", "messages", "pt");
    Translator.add("order.description", "Descri\u00e7\u00e3o", "messages", "pt");
    Translator.add("order.unit_price", "Pre\u00e7o unit\u00e1rio", "messages", "pt");
    Translator.add("order.qty", "Quantidade", "messages", "pt");
    Translator.add("order.qty_box", "Box Qty", "messages", "pt");
    Translator.add("order.date", "Data", "messages", "pt");
    Translator.add("order.mobile", "M\u00f3vel", "messages", "pt");
    Translator.add("order.phone", "telefone", "messages", "pt");
    Translator.add("order.total_price", "Pre\u00e7o total", "messages", "pt");
    Translator.add("order.total_items", "Itens totais", "messages", "pt");
    Translator.add("order.download", "Download", "messages", "pt");
    Translator.add("order.items_total", "Subtotal", "messages", "pt");
    Translator.add("order.total", "Total", "messages", "pt");
    Translator.add("order.state", "Estado da Encomenda", "messages", "pt");
    Translator.add("order.state.0", "Novo", "messages", "pt");
    Translator.add("order.state.1", "Em Verifica\u00e7\u00e3o", "messages", "pt");
    Translator.add("order.state.2", "Validado", "messages", "pt");
    Translator.add("password.request_new", "Solicitar novo", "messages", "pt");
    Translator.add("placeholder.category", "Selecione uma categoria", "messages", "pt");
    Translator.add("placeholder.current_password", "Sua senha atual", "messages", "pt");
    Translator.add("placeholder.first_name", "insira seu primeiro nome", "messages", "pt");
    Translator.add("placeholder.last_name", "insira seu sobrenome", "messages", "pt");
    Translator.add("placeholder.email", "insira seu e-mail", "messages", "pt");
    Translator.add("placeholder.password", "insira uma senha", "messages", "pt");
    Translator.add("placeholder.company.name", "Nome da empresa", "messages", "pt");
    Translator.add("placeholder.company.vat_country", "Pa\u00eds (registro)", "messages", "pt");
    Translator.add("placeholder.company.vat_number", "N\u00famero de IVA", "messages", "pt");
    Translator.add("placeholder.company.mobile", "M\u00f3vel", "messages", "pt");
    Translator.add("placeholder.company.phone", "telefone", "messages", "pt");
    Translator.add("placeholder.company.type.select", "Selecione uma opc\u00e3o", "messages", "pt");
    Translator.add("placeholder.company.type.designer", "Designers & Arquitectos", "messages", "pt");
    Translator.add("placeholder.company.type.b2b", "Profissional", "messages", "pt");
    Translator.add("placeholder.company.type.platforms", "Platforma Online", "messages", "pt");
    Translator.add("placeholder.company.type.b2c", "Particular", "messages", "pt");
    Translator.add("placeholder.company.type.others", "Outros", "messages", "pt");
    Translator.add("password", "Senha", "messages", "pt");
    Translator.add("placeholder.date_of_birth", "sua data de nascimento", "messages", "pt");
    Translator.add("placeholder.company", "nome da sua empresa", "messages", "pt");
    Translator.add("placeholder.message", "sua mensagem", "messages", "pt");
    Translator.add("placeholder.mobile", "n\u00famero de celular", "messages", "pt");
    Translator.add("placeholder.name", "seu nome", "messages", "pt");
    Translator.add("placeholder.new_password", "Sua nova senha", "messages", "pt");
    Translator.add("placeholder.newsletter.subscribe", "Inscreva-se para receber nossas \u00faltimas not\u00edcias", "messages", "pt");
    Translator.add("placeholder.phone", "um n\u00famero de telefone", "messages", "pt");
    Translator.add("placeholder.registration_email", "e-mail de registro", "messages", "pt");
    Translator.add("placeholder.search_price_min", "pre\u00e7o m\u00ednimo", "messages", "pt");
    Translator.add("placeholder.search_price_max", "pre\u00e7o m\u00e1ximo", "messages", "pt");
    Translator.add("placeholder.subject", "escolha o melhor assunto", "messages", "pt");
    Translator.add("product.variants", "Variantes", "messages", "pt");
    Translator.add("phone", "telefone", "messages", "pt");
    Translator.add("price_increment", "Incremento de pre\u00e7o", "messages", "pt");
    Translator.add("latest_products", "\u00daltimos Produtos", "messages", "pt");
    Translator.add("products", "Produtos", "messages", "pt");
    Translator.add("product.header", "Os nossos produtos", "messages", "pt");
    Translator.add("product.price.message", "para ver o pre\u00e7o e mais informa\u00e7\u00f5es", "messages", "pt");
    Translator.add("product.search_again", "Nenhum produto encontrado com os par\u00e2metros fornecidos. Por favor, tente uma pesquisa diferente.", "messages", "pt");
    Translator.add("product.model", "Modelo", "messages", "pt");
    Translator.add("product.packing_list", "Lista de embalagem", "messages", "pt");
    Translator.add("product.sku", "SKU", "messages", "pt");
    Translator.add("product.image", "Imagem", "messages", "pt");
    Translator.add("product.description", "Descri\u00e7\u00e3o", "messages", "pt");
    Translator.add("product.price", "Pre\u00e7o", "messages", "pt");
    Translator.add("quantity_box", "Unid", "messages", "pt");
    Translator.add("product.packing_weight.unit", "Unidade de peso", "messages", "pt");
    Translator.add("product.packing_size.unit", "Unidade SQM", "messages", "pt");
    Translator.add("product.packing_weight.total", "Peso total", "messages", "pt");
    Translator.add("product.packing_size.total", "SQM total", "messages", "pt");
    Translator.add("orders", "Encomendas", "messages", "pt");
    Translator.add("profile.orders", "\u00daltimas encomendas", "messages", "pt");
    Translator.add("profile.edit.current_password", "senha atual", "messages", "pt");
    Translator.add("profile.edit.new_password", "Nova senha", "messages", "pt");
    Translator.add("profile.header.details", "Detalhes", "messages", "pt");
    Translator.add("profile.header.company_info", "Informa\u00e7\u00e3o da empresa", "messages", "pt");
    Translator.add("profile.header.password", "Mudar senha", "messages", "pt");
    Translator.add("profile.header.personal_info", "Informa\u00e7\u00e3o pessoal", "messages", "pt");
    Translator.add("profile.menu_1", "Informa\u00e7\u00e3o pessoal", "messages", "pt");
    Translator.add("profile.menu_2", "Informa\u00e7\u00f5es da Empresa", "messages", "pt");
    Translator.add("profile.menu_3", "Mudar senha", "messages", "pt");
    Translator.add("profile.menu_4", "Baixar lista de pre\u00e7os", "messages", "pt");
    Translator.add("profile.menu_5", "Endere\u00e7o principal", "messages", "pt");
    Translator.add("profile.show", "Mostrar Perfil", "messages", "pt");
    Translator.add("qty", "Quantidade", "messages", "pt");
    Translator.add("reference.min", "ref.", "messages", "pt");
    Translator.add("register", "registo", "messages", "pt");
    Translator.add("registration.header.h2", "Registre-se para se tornar um cliente", "messages", "pt");
    Translator.add("registration.header.comapany", "Informa\u00e7\u00e3o da empresa", "messages", "pt");
    Translator.add("registration.header.user", "Os seus dados", "messages", "pt");
    Translator.add("registration.check_email.header", "Valida\u00e7\u00e3o de e-mail", "messages", "pt");
    Translator.add("registration.check_email.message", "N\u00f3s verificaremos as informa\u00e7\u00f5es da sua empresa que isso pode demorar as 24h antes de lhe enviar um e-mail. <br \/> <br \/> <b> Uma vez que verificamos as informa\u00e7\u00f5es da sua empresa, voc\u00ea ter\u00e1 acesso completo ao nosso site <\/ b>. < br \/> Mantenha contato. <br \/> ", "messages", "pt");
    Translator.add("resetting.header", "Recuperar senha", "messages", "pt");
    Translator.add("resetting.new_password.header", "Definir nova senha", "messages", "pt");
    Translator.add("resetting.invalid_email", "%username% n\u00e3o est\u00e1 registrado", "messages", "pt");
    Translator.add("resetting.check_email.header", "E-mail enviado", "messages", "pt");
    Translator.add("resetting.check_email.message", "Por favor, verifique sua caixa de entrada, acabamos de lhe enviar um e-mail de reset. <br\/> Se voc\u00ea encontrar dificuldades, sinta-se livre para <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"> <strong> entre em contato <\/ strong> <\/a> ", "messages", "pt");
    Translator.add("resetting.password_already_requested.header", "Reiniciar j\u00e1 solicitado", "messages", "pt");
    Translator.add("resetting.password_already_requested.message", "N\u00f3s j\u00e1 lhe enviamos um e-mail de reinicializa\u00e7\u00e3o, por favor verifique sua caixa de entrada <br\/> Se voc\u00ea encontrar dificuldades, sinta-se livre para <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"> < forte> contate-nos <\/ strong> <\/a> ", "messages", "pt");
    Translator.add("resetting.wait_validation.header", "Valida\u00e7\u00e3o ausente", "messages", "pt");
    Translator.add("resetting.wait_validation.message", "Aguarde que possamos validar as informa\u00e7\u00f5es da sua empresa. <br\/> Se j\u00e1 passou 24h desde o seu registro, sinta-se livre para <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"> <strong > contate-nos <\/ strong> <\/a> ", "messages", "pt");
    Translator.add("search", "Pesquisa", "messages", "pt");
    Translator.add("search_here", "procure aqui", "messages", "pt");
    Translator.add("send", "Enviar", "messages", "pt");
    Translator.add("show_more", "Mostre mais", "messages", "pt");
    Translator.add("subject", "Sujeito", "messages", "pt");
    Translator.add("shop.logo.alt", "K-lighting By Candibambu", "messages", "pt");
    Translator.add("shopping.cart.header", "Carrinho de compras", "messages", "pt");
    Translator.add("sub_total", "subtotal", "messages", "pt");
    Translator.add("to", "Para", "messages", "pt");
    Translator.add("total_qty", "Quantidade", "messages", "pt");
    Translator.add("total_price", "Pre\u00e7o total", "messages", "pt");
    Translator.add("type", "Tipo", "messages", "pt");
    Translator.add("votes", "Votos", "messages", "pt");
    Translator.add("view_cart", "Ver carrinho", "messages", "pt");
    Translator.add("where_we_are", "Onde estamos?", "messages", "pt");
    Translator.add("next", "Seguinte", "messages", "pt");
    Translator.add("profile", "Perfil", "messages", "pt");
    Translator.add("price_list", "Tabelas de Pre\u00e7os", "messages", "pt");
    Translator.add("variant.not_selected", "Sem Mudan\u00e7as", "messages", "pt");
    Translator.add("proforma.info", "Proforma", "messages", "pt");
    Translator.add("popup_title_1", "Subscreva a nossa newsletter", "messages", "pt");
    Translator.add("popup_title_2", "e receba novidades exclusivas", "messages", "pt");
    Translator.add("subscribe_now", "Subscrever!", "messages", "pt");
    Translator.add("popup_not_spam", "Pode subscrever. Nos n\u00e3o vamos fazer spam. Pode sair a qualquer momento.", "messages", "pt");
    Translator.add("call_costs_land", "(\u00b9 Aplicam-se as tarifas telef\u00f3nicas locais e nacionais)", "messages", "pt");
    Translator.add("cookies", "Este site usa cookies para garantir a sua melhor experi\u00eancia no nosso site. <a aria-label = \"saiba mais sobre cookies\" role=\"button\"         tabindex=\"0\" class=\"cc-link\" href=\"https:\/\/cookiesandyou.com\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">Saber Mais<\/a>", "messages", "pt");
    Translator.add("livro_reclamacoes", "Livro de Reclama\u00e7\u00f5es", "messages", "pt");
})(Translator);
