(function (Translator) {
    // en
    Translator.add("about_us", "About Us", "messages", "en");
    Translator.add("add", "Add", "messages", "en");
    Translator.add("address", "Address", "messages", "en");
    Translator.add("attachment.label.description", "Description", "messages", "en");
    Translator.add("add_to_cart", "Add to cart", "messages", "en");
    Translator.add("back", "Back", "messages", "en");
    Translator.add("dynamic_page", "News", "messages", "en");
    Translator.add("dynamic_page.no_results", "No Results found.", "messages", "en");
    Translator.add("dynamic_page.search_again", "Please try another search", "messages", "en");
    Translator.add("dynamic_page.menu.categories", "Categories", "messages", "en");
    Translator.add("menu.categories", "Categories", "messages", "en");
    Translator.add("dynamic_page.menu.latest", "Recent posts", "messages", "en");
    Translator.add("dynamic_page.more_info", "+ Info", "messages", "en");
    Translator.add("dynamic_page.posts", "Recent posts", "messages", "en");
    Translator.add("browse", "Browse", "messages", "en");
    Translator.add("button.back", "Back", "messages", "en");
    Translator.add("button.registration", "Register", "messages", "en");
    Translator.add("button.save", "Save", "messages", "en");
    Translator.add("cancel", "Cancel", "messages", "en");
    Translator.add("categories", "Categories", "messages", "en");
    Translator.add("change", "Change", "messages", "en");
    Translator.add("close", "Close", "messages", "en");
    Translator.add("company", "Company", "messages", "en");
    Translator.add("company.header.main_address", "Main Address", "messages", "en");
    Translator.add("company_name", "company name", "messages", "en");
    Translator.add("company_vat_country", "VAT Country", "messages", "en");
    Translator.add("company_vat_number", "VAT Number", "messages", "en");
    Translator.add("contact_us", "Contact Us", "messages", "en");
    Translator.add("contact_us.subject.1", "I want some information", "messages", "en");
    Translator.add("contact_us.title", "We Expect Your Contact", "messages", "en");
    Translator.add("contacts", "Contacts", "messages", "en");
    Translator.add("country.vat.at", "Austria", "messages", "en");
    Translator.add("country.vat.be", "Belgium", "messages", "en");
    Translator.add("country.vat.bg", "Bulgaria", "messages", "en");
    Translator.add("country.vat.cy", "Cyprus", "messages", "en");
    Translator.add("country.vat.cz", "Check Republic", "messages", "en");
    Translator.add("country.vat.dk", "Denmark", "messages", "en");
    Translator.add("country.vat.ee", "Estonia", "messages", "en");
    Translator.add("country.vat.fi", "Filand", "messages", "en");
    Translator.add("country.vat.fr", "France", "messages", "en");
    Translator.add("country.vat.de", "Germany", "messages", "en");
    Translator.add("country.vat.el", "Greece", "messages", "en");
    Translator.add("country.vat.hu", "Hungary", "messages", "en");
    Translator.add("country.vat.ie", "Ireland", "messages", "en");
    Translator.add("country.vat.it", "Italy", "messages", "en");
    Translator.add("country.vat.lv", "Latvia", "messages", "en");
    Translator.add("country.vat.lt", "Lithuania", "messages", "en");
    Translator.add("country.vat.lu", "Luxembourg", "messages", "en");
    Translator.add("country.vat.mt", "Malta", "messages", "en");
    Translator.add("country.vat.nl", "Netherlands", "messages", "en");
    Translator.add("country.vat.ot", "Other", "messages", "en");
    Translator.add("country.vat.pl", "Poland", "messages", "en");
    Translator.add("country.vat.pt", "Portugal", "messages", "en");
    Translator.add("country.vat.ro", "Romania", "messages", "en");
    Translator.add("country.vat.sk", "Slovakia", "messages", "en");
    Translator.add("country.vat.si", "Slovenia", "messages", "en");
    Translator.add("country.vat.es", "Spain", "messages", "en");
    Translator.add("country.vat.se", "Sweden", "messages", "en");
    Translator.add("country.vat.gb", "United kingdom", "messages", "en");
    Translator.add("country.vat.zz.other", "Non EU country", "messages", "en");
    Translator.add("date_of_birth", "Date Of Birth", "messages", "en");
    Translator.add("date_string", "%day% of %month%, %year%", "messages", "en");
    Translator.add("default_currency_symbol", "\u20ac", "messages", "en");
    Translator.add("delete", "Delete", "messages", "en");
    Translator.add("description", "Description", "messages", "en");
    Translator.add("do_order", "Confirm Order", "messages", "en");
    Translator.add("download", "Download", "messages", "en");
    Translator.add("DIALOGS_OK", "OK", "messages", "en");
    Translator.add("email", "Email", "messages", "en");
    Translator.add("error", "Error", "messages", "en");
    Translator.add("faqs", "FAQS", "messages", "en");
    Translator.add("faqs.title", "Frequently Asked Questions", "messages", "en");
    Translator.add("first_name", "First Name", "messages", "en");
    Translator.add("footer.top.message", "Do You Need Help", "messages", "en");
    Translator.add("footer.copyrights", "Made with <span class=\"fa fa-heart\"><\/span> by <i><a class=\"text-white\" href=\"https:\/\/igniteyourproject.com\/\" target=\"_blank\" >Ignite Your Project<\/a><\/i>.", "messages", "en");
    Translator.add("form.address.address_line_1", "address line", "messages", "en");
    Translator.add("form.address.address_line_2", "address line (optional)", "messages", "en");
    Translator.add("form.address.postal_code", "postal code", "messages", "en");
    Translator.add("form.address.locality", "locality", "messages", "en");
    Translator.add("form.address.city", "city", "messages", "en");
    Translator.add("form.address.country", "country", "messages", "en");
    Translator.add("form_email_example", "(eg: geral@candibambu.pt)", "messages", "en");
    Translator.add("full_name", "fullname", "messages", "en");
    Translator.add("gender", "Gender", "messages", "en");
    Translator.add("gender_female", "Female", "messages", "en");
    Translator.add("gender_male", "Male", "messages", "en");
    Translator.add("get_connected", "Get Connected", "messages", "en");
    Translator.add("home", "Home", "messages", "en");
    Translator.add("homepage", "Homepage", "messages", "en");
    Translator.add("homepage.title", "More than lighting or decoration it give's you well being", "messages", "en");
    Translator.add("id", "ID", "messages", "en");
    Translator.add("icon.flow.register", "Create an account", "messages", "en");
    Translator.add("icon.flow.email_confirmation", "Account will be validated", "messages", "en");
    Translator.add("icon.flow.verified", "You can see all the info", "messages", "en");
    Translator.add("last_name", "Last Name", "messages", "en");
    Translator.add("language.en", "English", "messages", "en");
    Translator.add("language.es", "Spanish", "messages", "en");
    Translator.add("language.fr", "French", "messages", "en");
    Translator.add("language.pt", "Portuguese", "messages", "en");
    Translator.add("limit_2MB", "2 MB LIMIT", "messages", "en");
    Translator.add("limit_4MB", "4MB Max", "messages", "en");
    Translator.add("file_upload", "Upload a File", "messages", "en");
    Translator.add("location", "Location", "messages", "en");
    Translator.add("login", "Login", "messages", "en");
    Translator.add("login.header", "WELCOME TO K-LIGHTING", "messages", "en");
    Translator.add("login.forgot_password", "Forgot password?", "messages", "en");
    Translator.add("login.register", "Create an account", "messages", "en");
    Translator.add("message", "Message", "messages", "en");
    Translator.add("mobile", "Mobile", "messages", "en");
    Translator.add("more_details", "More Details", "messages", "en");
    Translator.add("name", "Name", "messages", "en");
    Translator.add("newsletter.registration.success.header", "Newsletter Subscribe", "messages", "en");
    Translator.add("newsletter.registration.success.body", "You will from now on receive our latest news. Thank you for your subscription!", "messages", "en");
    Translator.add("no-image", "Default no product image", "messages", "en");
    Translator.add("order", "Order", "messages", "en");
    Translator.add("order.info", "Online Order", "messages", "en");
    Translator.add("order.disclaimer.before", "We will get the carriage cost for your order and inform you about the cost once we get the best price.", "messages", "en");
    Translator.add("order.disclaimer", "We are currently working on get the carriage cost for your order. We will inform you about the cost once we get the best price.", "messages", "en");
    Translator.add("order.from", "From", "messages", "en");
    Translator.add("order.email", "Email", "messages", "en");
    Translator.add("order.number", "Order n.", "messages", "en");
    Translator.add("order.description", "Description", "messages", "en");
    Translator.add("order.unit_price", "Unit Price", "messages", "en");
    Translator.add("order.qty", "Quantity", "messages", "en");
    Translator.add("order.qty_box", "Box Qty", "messages", "en");
    Translator.add("order.date", "Date", "messages", "en");
    Translator.add("order.mobile", "Mobile", "messages", "en");
    Translator.add("order.phone", "Phone", "messages", "en");
    Translator.add("order.total_price", "Total Price", "messages", "en");
    Translator.add("order.total_items", "Total Items", "messages", "en");
    Translator.add("order.download", "Download", "messages", "en");
    Translator.add("order.items_total", "Sub-total", "messages", "en");
    Translator.add("order.total", "Total", "messages", "en");
    Translator.add("order.state", "Order Status", "messages", "en");
    Translator.add("order.state.0", "New", "messages", "en");
    Translator.add("order.state.1", "Verifiyng", "messages", "en");
    Translator.add("order.state.2", "Valid", "messages", "en");
    Translator.add("password.request_new", "Request New", "messages", "en");
    Translator.add("placeholder.category", "select a category", "messages", "en");
    Translator.add("placeholder.current_password", "your current password", "messages", "en");
    Translator.add("placeholder.first_name", "insert your first name", "messages", "en");
    Translator.add("placeholder.last_name", "insert your last name", "messages", "en");
    Translator.add("placeholder.email", "insert your email", "messages", "en");
    Translator.add("placeholder.password", "insert a password", "messages", "en");
    Translator.add("placeholder.company.name", "Company name", "messages", "en");
    Translator.add("placeholder.company.vat_country", "Country (registration)", "messages", "en");
    Translator.add("placeholder.company.vat_number", "Vat number", "messages", "en");
    Translator.add("placeholder.company.mobile", "Mobile", "messages", "en");
    Translator.add("placeholder.company.phone", "Phone", "messages", "en");
    Translator.add("placeholder.company.type.select", "Select an option", "messages", "en");
    Translator.add("placeholder.company.type.designer", "Designer & Architects", "messages", "en");
    Translator.add("placeholder.company.type.b2b", "Professional", "messages", "en");
    Translator.add("placeholder.company.type.platforms", "Online Platforms", "messages", "en");
    Translator.add("placeholder.company.type.b2c", "Private Client", "messages", "en");
    Translator.add("placeholder.company.type.others", "Others", "messages", "en");
    Translator.add("password", "Password", "messages", "en");
    Translator.add("placeholder.date_of_birth", "your date of birth", "messages", "en");
    Translator.add("placeholder.company", "your company's name", "messages", "en");
    Translator.add("placeholder.message", "your message", "messages", "en");
    Translator.add("placeholder.mobile", "mobile phone number", "messages", "en");
    Translator.add("placeholder.name", "your name", "messages", "en");
    Translator.add("placeholder.new_password", "your new password", "messages", "en");
    Translator.add("placeholder.newsletter.subscribe", "sign up to receive our latest news", "messages", "en");
    Translator.add("placeholder.phone", "a phone number", "messages", "en");
    Translator.add("placeholder.registration_email", "registration email", "messages", "en");
    Translator.add("placeholder.search_price_min", "min price", "messages", "en");
    Translator.add("placeholder.search_price_max", "max price", "messages", "en");
    Translator.add("placeholder.subject", "choose the best subject", "messages", "en");
    Translator.add("product.variants", "Variants", "messages", "en");
    Translator.add("phone", "Phone", "messages", "en");
    Translator.add("price_increment", "Price Increment", "messages", "en");
    Translator.add("latest_products", "Latest Products", "messages", "en");
    Translator.add("product.header", "Our Products", "messages", "en");
    Translator.add("product.price.message", "to view the price and more info", "messages", "en");
    Translator.add("product.search_again", "No products found with the given parameters. Please try a different search.", "messages", "en");
    Translator.add("product.model", "Model", "messages", "en");
    Translator.add("product.packing_list", "Packing List", "messages", "en");
    Translator.add("product.sku", "SKU", "messages", "en");
    Translator.add("quantity_box", "Items", "messages", "en");
    Translator.add("product.packing_weight.unit", "Unit Weight", "messages", "en");
    Translator.add("product.packing_size.unit", "Unit SQM", "messages", "en");
    Translator.add("product.packing_weight.total", "Total Weight", "messages", "en");
    Translator.add("product.packing_size.total", "Total SQM", "messages", "en");
    Translator.add("orders", "Latest Orders", "messages", "en");
    Translator.add("profile.orders", "Latest Orders", "messages", "en");
    Translator.add("profile.edit.current_password", "Current Password", "messages", "en");
    Translator.add("profile.edit.new_password", "New Password", "messages", "en");
    Translator.add("profile.header.details", "Details", "messages", "en");
    Translator.add("profile.header.company_info", "Company Info", "messages", "en");
    Translator.add("profile.header.password", "Change Password", "messages", "en");
    Translator.add("profile.header.personal_info", "Personal Info", "messages", "en");
    Translator.add("profile.menu_1", "Personal Information", "messages", "en");
    Translator.add("profile.menu_2", "Company Information", "messages", "en");
    Translator.add("profile.menu_3", "Change Password", "messages", "en");
    Translator.add("profile.menu_4", "Download Price List", "messages", "en");
    Translator.add("profile.menu_5", "Main Address", "messages", "en");
    Translator.add("profile.show", "Show Profile", "messages", "en");
    Translator.add("qty", "Quantity", "messages", "en");
    Translator.add("reference.min", "ref.", "messages", "en");
    Translator.add("register", "Register", "messages", "en");
    Translator.add("registration.header.h2", "Register to become a client", "messages", "en");
    Translator.add("registration.header.comapany", "Company Info", "messages", "en");
    Translator.add("registration.header.user", "Your Info", "messages", "en");
    Translator.add("registration.check_email.header", "Email validation", "messages", "en");
    Translator.add("registration.check_email.message", "We will check your company information this can take upon 24h before we send you a email.<br \/><br \/> <b>Once we check your company information you will have full access to our website<\/b>. <br \/>Please stay in touch.<br \/>", "messages", "en");
    Translator.add("resetting.header", "Recover Password", "messages", "en");
    Translator.add("resetting.new_password.header", "Define New Password", "messages", "en");
    Translator.add("resetting.invalid_email", "%username% is not registered", "messages", "en");
    Translator.add("resetting.check_email.header", "Email Sent", "messages", "en");
    Translator.add("resetting.check_email.message", "Please check your inbox, we've just sent you a reset email.<br\/>If you encounter difficulties feel free to <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"><strong>contact us<\/strong><\/a>", "messages", "en");
    Translator.add("resetting.password_already_requested.header", "Reset Already Requested", "messages", "en");
    Translator.add("resetting.password_already_requested.message", "We've already sent you a reset email, please check your inbox<br\/>If you encounter difficulties feel free to <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"><strong>contact us<\/strong><\/a>", "messages", "en");
    Translator.add("resetting.wait_validation.header", "Validation Missing", "messages", "en");
    Translator.add("resetting.wait_validation.message", "Please wait for us to validate your company information.<br\/>If already passed 24h since your registration feel free to <a href=\"mailto:geral@candibambu.pt\" target=\"_top\"><strong>contact us<\/strong><\/a>", "messages", "en");
    Translator.add("search", "Search", "messages", "en");
    Translator.add("search_here", "search here", "messages", "en");
    Translator.add("send", "Send", "messages", "en");
    Translator.add("show_more", "Show More", "messages", "en");
    Translator.add("subject", "Subject", "messages", "en");
    Translator.add("shop.logo.alt", "K-lighting By Candibambu", "messages", "en");
    Translator.add("shopping.cart.header", "Your shopping cart", "messages", "en");
    Translator.add("sub_total", "sub-total", "messages", "en");
    Translator.add("to", "To", "messages", "en");
    Translator.add("total_qty", "Quantity", "messages", "en");
    Translator.add("total_price", "Total Price", "messages", "en");
    Translator.add("type", "Type", "messages", "en");
    Translator.add("variant.not_selected", "Without Changes", "messages", "en");
    Translator.add("view_cart", "View cart", "messages", "en");
    Translator.add("votes", "Votes", "messages", "en");
    Translator.add("where_we_are", "Where are we?", "messages", "en");
    Translator.add("next", "Next", "messages", "en");
    Translator.add("profile", "Profile", "messages", "en");
    Translator.add("proforma.info", "Proforma Invoice", "messages", "en");
    Translator.add("popup_title_1", "SUBSCRIBE TO OUR EMAIL LIST", "messages", "en");
    Translator.add("popup_title_2", "FOR EXCLUSIVE NEWS", "messages", "en");
    Translator.add("subscribe_now", "Subscribe Now!", "messages", "en");
    Translator.add("popup_not_spam", "We promise we won't spam you. You can unsubscribe anytime.", "messages", "en");
    Translator.add("call_costs_land", "(\u00b9 Local and national telephone rates apply)", "messages", "en");
    Translator.add("cookies", "This website uses cookies to ensure you get the best experience on our website. <a aria-label=\"learn more about cookies\" role=\"button\" tabindex=\"0\" class=\"cc-link\" href=\"https:\/\/cookiesandyou.com\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">Learn more<\/a>", "messages", "en");
    Translator.add("livro_reclamacoes", "Complaints book", "messages", "en");
})(Translator);
