(function (Translator) {
    // en
    Translator.add("sonata_seo_homepage_breadcrumb", "Home", "SonataSeoBundle", "en");
    Translator.add("sonata_seo_share_by_email", "Share by email", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.email.share_button", "Share via E-Mail", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.facebook.like_box", "Facebook - Likebox", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.facebook.like_button", "Facebook - Like button", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.facebook.send_button", "Facebook - Send", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.facebook.share_button", "Facebook - Sjare", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.twitter.share_button", "Twitter - Share", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.twitter.follow_button", "Twitter - Follow", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.twitter.hashtag_button", "Twitter - Hashtag", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.twitter.mention_button", "Twitter - Mention", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.twitter.embed", "Twitter - Embed", "SonataSeoBundle", "en");
    Translator.add("sonata.seo.block.pinterest.pin_button", "Pinterest - Pin", "SonataSeoBundle", "en");
    Translator.add("form.label_colorscheme", "Colorschema", "SonataSeoBundle", "en");
    Translator.add("form.label_colorscheme_light", "light", "SonataSeoBundle", "en");
    Translator.add("form.label_colorscheme_dark", "dark", "SonataSeoBundle", "en");
    Translator.add("form.label_subject", "Subject", "SonataSeoBundle", "en");
    Translator.add("form.label_body", "Body", "SonataSeoBundle", "en");
    Translator.add("form.label_url", "Url", "SonataSeoBundle", "en");
    Translator.add("form.label_width", "Width", "SonataSeoBundle", "en");
    Translator.add("form.label_height", "Height", "SonataSeoBundle", "en");
    Translator.add("form.label_show_faces", "Show faces", "SonataSeoBundle", "en");
    Translator.add("form.label_show_header", "Show header", "SonataSeoBundle", "en");
    Translator.add("form.label_show_posts", "Show posts", "SonataSeoBundle", "en");
    Translator.add("form.label_show_border", "Show border", "SonataSeoBundle", "en");
    Translator.add("form.label_layout", "Layout", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_standard", "standard", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_box_count", "box count", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_button_count", "button count", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_button", "button", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_icon_link", "icon with link", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_icon", "icon", "SonataSeoBundle", "en");
    Translator.add("form.label_layout_link", "link", "SonataSeoBundle", "en");
    Translator.add("form.label_action", "Action", "SonataSeoBundle", "en");
    Translator.add("form.label_action_like", "like", "SonataSeoBundle", "en");
    Translator.add("form.label_action_recommend", "recommend", "SonataSeoBundle", "en");
    Translator.add("form.label_share", "Share", "SonataSeoBundle", "en");
    Translator.add("form.label_image", "Image", "SonataSeoBundle", "en");
    Translator.add("form.label_description", "Description", "SonataSeoBundle", "en");
    Translator.add("form.label_size", "Size", "SonataSeoBundle", "en");
    Translator.add("form.label_shape", "Shape", "SonataSeoBundle", "en");
    Translator.add("form.label_shape_rectangular", "rectangular", "SonataSeoBundle", "en");
    Translator.add("form.label_shape_round", "round", "SonataSeoBundle", "en");
    Translator.add("form.label_tweet", "Tweet", "SonataSeoBundle", "en");
    Translator.add("form.label_maxwidth", "Max width", "SonataSeoBundle", "en");
    Translator.add("form.label_hide_media", "Hide media", "SonataSeoBundle", "en");
    Translator.add("form.label_hide_thread", "Hide thread", "SonataSeoBundle", "en");
    Translator.add("form.label_omit_script", "Omit script", "SonataSeoBundle", "en");
    Translator.add("form.label_align", "Align", "SonataSeoBundle", "en");
    Translator.add("form.label_align_left", "left", "SonataSeoBundle", "en");
    Translator.add("form.label_align_right", "right", "SonataSeoBundle", "en");
    Translator.add("form.label_align_center", "center", "SonataSeoBundle", "en");
    Translator.add("form.label_align_none", "none", "SonataSeoBundle", "en");
    Translator.add("form.label_related", "Related", "SonataSeoBundle", "en");
    Translator.add("form.label_lang", "Language", "SonataSeoBundle", "en");
    Translator.add("form.label_user", "User", "SonataSeoBundle", "en");
    Translator.add("form.label_show_username", "Show username", "SonataSeoBundle", "en");
    Translator.add("form.label_large_button", "Large button", "SonataSeoBundle", "en");
    Translator.add("form.label_opt_out", "Opt out", "SonataSeoBundle", "en");
    Translator.add("form.label_language", "Language", "SonataSeoBundle", "en");
    Translator.add("form.label_hashtag", "Hashtag", "SonataSeoBundle", "en");
    Translator.add("form.label_text", "Text", "SonataSeoBundle", "en");
    Translator.add("form.label_recommend", "Recommend", "SonataSeoBundle", "en");
    Translator.add("form.label_show_count", "Show count", "SonataSeoBundle", "en");
    Translator.add("form.label_via", "Via", "SonataSeoBundle", "en");
    Translator.add("form.help_tweet", "Tweet id, URL or content. If you put the HTML content directly, other options won\\'t be handled.", "SonataSeoBundle", "en");
    Translator.add("form.help_maxwidth", "Must be contained between 250 and 550.", "SonataSeoBundle", "en");
    Translator.add("form.help_hide_media", "Hide media contained in tweet?", "SonataSeoBundle", "en");
    Translator.add("form.help_hide_thread", "Show discussion?", "SonataSeoBundle", "en");
    Translator.add("form.help_omit_script", "Should be checked once and only once per page. If you embed several tweets in the page, uncheck this on the other ones.", "SonataSeoBundle", "en");
    Translator.add("form.help_related", "See 'related' argument in https:\/\/dev.twitter.com\/docs\/intents", "SonataSeoBundle", "en");
})(Translator);
