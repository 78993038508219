(function (Translator) {
    // pt
    Translator.add("title_user_registration", "Registar", "SonataUserBundle", "pt");
    Translator.add("title_user_account", "Perfil do Utilizador", "SonataUserBundle", "pt");
    Translator.add("title_user_edit_profile", "Editar", "SonataUserBundle", "pt");
    Translator.add("title_user_authentication", "Autentica\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("sonata_user_submit", "Submeter", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_list", "Utilizadores", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_create", "Criar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_edit", "Editar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_delete", "Eliminar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_list", "Grupos", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_create", "Criar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_edit", "Editar", "SonataUserBundle", "pt");
    Translator.add("users", "Utilizadores", "SonataUserBundle", "pt");
    Translator.add("groups", "Grupos", "SonataUserBundle", "pt");
    Translator.add("switch_user_exit", "Sair do modo de personifica\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("switch_user", "Personificar utilizador", "SonataUserBundle", "pt");
    Translator.add("user_block_logout", "Terminar sess\u00e3o", "SonataUserBundle", "pt");
    Translator.add("user_block_profile", "Perfil", "SonataUserBundle", "pt");
    Translator.add("form.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("form.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("form.label_plain_password", "Palavra-passe", "SonataUserBundle", "pt");
    Translator.add("form.label_groups", "Grupos", "SonataUserBundle", "pt");
    Translator.add("form.label_roles", "Tipos de utilizador", "SonataUserBundle", "pt");
    Translator.add("form.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("form.label_expired", "Expirado", "SonataUserBundle", "pt");
    Translator.add("form.label_enabled", "Activo", "SonataUserBundle", "pt");
    Translator.add("form.label_credentials_expired", "Credenciais expiradas", "SonataUserBundle", "pt");
    Translator.add("form.label_created_at", "Criado em", "SonataUserBundle", "pt");
    Translator.add("form.label_last_login", "\u00daltimo login", "SonataUserBundle", "pt");
    Translator.add("form.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("filter.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("filter.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("filter.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("filter.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("filter.label_id", "ID", "SonataUserBundle", "pt");
    Translator.add("filter.label_enabled", "Activo", "SonataUserBundle", "pt");
    Translator.add("filter.label_groups", "Groupos", "SonataUserBundle", "pt");
    Translator.add("filter.label_created_at", "Criado em", "SonataUserBundle", "pt");
    Translator.add("filter.label_last_login", "\u00daltimo login", "SonataUserBundle", "pt");
    Translator.add("filter.label_firstname", "Nome", "SonataUserBundle", "pt");
    Translator.add("filter.label_lastname", "Apelido", "SonataUserBundle", "pt");
    Translator.add("filter.label_roles", "Tipos de utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("list.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("list.label_groups", "Groupos", "SonataUserBundle", "pt");
    Translator.add("list.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("list.label_enabled", "Activo", "SonataUserBundle", "pt");
    Translator.add("list.label_created_at", "Criado em", "SonataUserBundle", "pt");
    Translator.add("list.label_last_login", "\u00daltimo login", "SonataUserBundle", "pt");
    Translator.add("list.label_impersonating", "Personificar utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_roles", "Tipos de utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_firstname", "Nome", "SonataUserBundle", "pt");
    Translator.add("list.label_lastname", "Apelido", "SonataUserBundle", "pt");
    Translator.add("label_two_step_code", "Verifica\u00e7\u00e3o em 2 Etapas", "SonataUserBundle", "pt");
    Translator.add("message_two_step_code_help", "Introduza o c\u00f3digo de verifica\u00e7\u00e3o gerado pela sua aplica\u00e7\u00e3o m\u00f3vel", "SonataUserBundle", "pt");
    Translator.add("label_two_step_code_error", "O c\u00f3digo de verifica\u00e7\u00e3o n\u00e3o \u00e9 v\u00e1lido", "SonataUserBundle", "pt");
    Translator.add("sonata_user", "Utilizadores", "SonataUserBundle", "pt");
    Translator.add("General", "Geral", "SonataUserBundle", "pt");
    Translator.add("Groups", "Groupos", "SonataUserBundle", "pt");
    Translator.add("Management", "Gest\u00e3o", "SonataUserBundle", "pt");
    Translator.add("field.label_roles_editable", "Tipos de utilizador", "SonataUserBundle", "pt");
    Translator.add("form.label_firstname", "Nome", "SonataUserBundle", "pt");
    Translator.add("form.label_lastname", "Apelido", "SonataUserBundle", "pt");
    Translator.add("form.label_website", "P\u00e1gina web", "SonataUserBundle", "pt");
    Translator.add("form.label_biography", "Biografia", "SonataUserBundle", "pt");
    Translator.add("form.label_gender", "Sexo", "SonataUserBundle", "pt");
    Translator.add("form.label_locale", "Idioma", "SonataUserBundle", "pt");
    Translator.add("form.label_timezone", "Fuso hor\u00e1rio", "SonataUserBundle", "pt");
    Translator.add("form.label_phone", "Telefone", "SonataUserBundle", "pt");
    Translator.add("form.label_facebook_uid", "Uid no Facebook", "SonataUserBundle", "pt");
    Translator.add("form.label_facebook_name", "Nome no Facebook", "SonataUserBundle", "pt");
    Translator.add("form.label_twitter_uid", "Uid no Twitter", "SonataUserBundle", "pt");
    Translator.add("form.label_twitter_name", "Nome no Twitter", "SonataUserBundle", "pt");
    Translator.add("form.label_gplus_uid", "Uid no Google+", "SonataUserBundle", "pt");
    Translator.add("form.label_gplus_name", "Nome no Google+", "SonataUserBundle", "pt");
    Translator.add("form.label_token", "Token", "SonataUserBundle", "pt");
    Translator.add("form.label_two_step_verification_code", "Verifica\u00e7\u00e3o em 2 etapas", "SonataUserBundle", "pt");
    Translator.add("show.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("show.label_email", "Email", "SonataUserBundle", "pt");
    Translator.add("form.label_date_of_birth", "Data de nascimento", "SonataUserBundle", "pt");
    Translator.add("show.label_groups", "Grupos", "SonataUserBundle", "pt");
    Translator.add("show.label_date_of_birth", "Data de nascimento", "SonataUserBundle", "pt");
    Translator.add("show.label_firstname", "Nome", "SonataUserBundle", "pt");
    Translator.add("show.label_lastname", "Apelido", "SonataUserBundle", "pt");
    Translator.add("show.label_website", "P\u00e1gina web", "SonataUserBundle", "pt");
    Translator.add("show.label_biography", "Biografia", "SonataUserBundle", "pt");
    Translator.add("show.label_gender", "Sexo", "SonataUserBundle", "pt");
    Translator.add("show.label_locale", "Idioma", "SonataUserBundle", "pt");
    Translator.add("show.label_timezone", "Fuso hor\u00e1rio", "SonataUserBundle", "pt");
    Translator.add("show.label_phone", "Telefone", "SonataUserBundle", "pt");
    Translator.add("show.label_facebook_uid", "Uid no Facebook", "SonataUserBundle", "pt");
    Translator.add("show.label_facebook_name", "Nome no Facebook", "SonataUserBundle", "pt");
    Translator.add("show.label_twitter_uid", "Uid no Twitter", "SonataUserBundle", "pt");
    Translator.add("show.label_twitter_name", "Nome no Twitter", "SonataUserBundle", "pt");
    Translator.add("show.label_gplus_uid", "Uid no Google+", "SonataUserBundle", "pt");
    Translator.add("show.label_gplus_name", "Nome no Google+", "SonataUserBundle", "pt");
    Translator.add("show.label_token", "Token", "SonataUserBundle", "pt");
    Translator.add("show.label_two_step_verification_code", "Verifica\u00e7\u00e3o em 2 estapas", "SonataUserBundle", "pt");
    Translator.add("show.label_created_at", "Criado em", "SonataUserBundle", "pt");
    Translator.add("show.label_last_login", "\u00daltimo login", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_show", "Mostra", "SonataUserBundle", "pt");
    Translator.add("gender_unknown", "Desconhecido", "SonataUserBundle", "pt");
    Translator.add("gender_female", "Feminino", "SonataUserBundle", "pt");
    Translator.add("gender_male", "Masculino", "SonataUserBundle", "pt");
    Translator.add("sonata_profile_title", "Painel de Controlo", "SonataUserBundle", "pt");
    Translator.add("link_show_profile", "Mostrar perfil", "SonataUserBundle", "pt");
    Translator.add("link_edit_profile", "Editar perfil", "SonataUserBundle", "pt");
    Translator.add("title_user_edit_authentication", "Autentica\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("link_edit_authentication", "Editar autentica\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("label_profile_gender", "Sexo", "SonataUserBundle", "pt");
    Translator.add("label_profile_firstname", "Nome", "SonataUserBundle", "pt");
    Translator.add("label_profile_lastname", "Apelido", "SonataUserBundle", "pt");
    Translator.add("label_profile_website", "P\u00e1gina web", "SonataUserBundle", "pt");
    Translator.add("label_profile_dob", "Data de nascimento", "SonataUserBundle", "pt");
    Translator.add("label_profile_biography", "Biografia", "SonataUserBundle", "pt");
    Translator.add("label_profile_locale", "Idioma", "SonataUserBundle", "pt");
    Translator.add("label_profile_timezone", "Fuso hor\u00e1rio", "SonataUserBundle", "pt");
    Translator.add("label_profile_phone", "Telefone", "SonataUserBundle", "pt");
    Translator.add("profile.flash.updated", "O seu perfil foi actualizado.", "SonataUserBundle", "pt");
    Translator.add("sonata_change_password_link", "Altere a sua palavra-passe", "SonataUserBundle", "pt");
    Translator.add("link_register", "Registar", "SonataUserBundle", "pt");
    Translator.add("link_login", "Entrar", "SonataUserBundle", "pt");
    Translator.add("link_logout", "Terminar sess\u00e3o", "SonataUserBundle", "pt");
    Translator.add("registration.flash.user_created", "A sua conta foi criada com sucesso", "SonataUserBundle", "pt");
    Translator.add("forgotten_password", "Esqueci-me da palavra-passe", "SonataUserBundle", "pt");
    Translator.add("sonata_user_profile_breadcrumb_index", "Painel de Controlo", "SonataUserBundle", "pt");
    Translator.add("sonata_user_profile_breadcrumb_edit", "Editar perfil", "SonataUserBundle", "pt");
    Translator.add("sonata_user_already_authenticated", "J\u00e1 tem sess\u00e3o iniciada", "SonataUserBundle", "pt");
    Translator.add("security.login.username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("security.login.password", "Palavra-passe", "SonataUserBundle", "pt");
    Translator.add("form.username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("form.email", "Email", "SonataUserBundle", "pt");
    Translator.add("form.password", "Palavra-passe", "SonataUserBundle", "pt");
    Translator.add("form.password_confirmation", "Confirma\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("title_user_edit_password", "title_user_edit_password", "SonataUserBundle", "pt");
    Translator.add("title_user_resetting", "title_user_resetting", "SonataUserBundle", "pt");
    Translator.add("title_user_resetting_reset", "title_user_resetting_reset", "SonataUserBundle", "pt");
})(Translator);
