(function (Translator) {
    // pt
    Translator.add("logo.inline", "logo\/logo_inline.png", "images", "pt");
    Translator.add("logo", "logo\/logo.png", "images", "pt");
    Translator.add("logo.alt", "Logo", "images", "pt");
    Translator.add("blog.main_photo.alt", "blog imagem primaria", "images", "pt");
    Translator.add("blog.post.picture.alt", "blog imagem do post", "images", "pt");
    Translator.add("homepage.main_photo.big", "homepage\/main_photo.jpg", "images", "pt");
    Translator.add("homepage.main_photo.alt", "Fotografia", "images", "pt");
})(Translator);
